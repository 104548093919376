export default {
  badge: 'Bricka',
  close: 'Stäng',
  dataIterator: {
    noResultsText: 'Inga poster funna',
    loadingText: 'Laddar data...',
  },
  dataTable: {
    itemsPerPageText: 'Rader per sida:',
    ariaLabel: {
      sortDescending: 'Sorterat fallande.',
      sortAscending: 'Sorterat stigande.',
      sortNone: 'Osorterat.',
      activateNone: 'Aktivera för att ta bort sortering.',
      activateDescending: 'Aktivera för sortering fallande.',
      activateAscending: 'Aktivera för sortering stigande.',
    },
    sortBy: 'Sortera efter',
  },
  dataFooter: {
    itemsPerPageText: 'Objekt per sida:',
    itemsPerPageAll: 'Alla',
    nextPage: 'Nästa sida',
    prevPage: 'Föregående sida',
    firstPage: 'Första sidan',
    lastPage: 'Sista sidan',
    pageText: '{0}-{1} av {2}',
  },
  datePicker: {
    itemsSelected: '{0} markerade',
    nextMonthAriaLabel: 'Nästa månad',
    nextYearAriaLabel: 'Nästa år',
    prevMonthAriaLabel: 'Förra månaden',
    prevYearAriaLabel: 'Förra året',
  },
  noDataText: 'Ingen data tillgänglig',
  carousel: {
    prev: 'Föregående vy',
    next: 'Nästa vy',
    ariaLabel: {
      delimiter: 'Karusell sida {0} av {1}',
    },
  },
  calendar: {
    moreEvents: '{0} fler',
  },
  fileInput: {
    counter: '{0} filer',
    counterSize: '{0} filer (av {1} totalt)',
  },
  timePicker: {
    am: 'fm',
    pm: 'em',
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Pagination Navigation',
      next: 'Nästa sida',
      previous: 'Föregående sida',
      page: 'Gå till sidan {0}',
      currentPage: 'Aktuell sida, sida {0}',
    },
  },
  rating: {
    ariaLabel: {
      icon: 'Betyg {0} av {1}',
    },
  },

  app: {
    home: {
      title: 'Hem',
      invitations: 'Mina inbjudningar'
    },
    menu: {
      modules: 'Moduler',
      history: 'Historik'
    }
  },

  info: {
    update: {
      success: 'Uppdatering genomförd'
    }
  },

  error: {
    default: 'Ett fel inträffade',
    request: 'Begäran kunde inte utföras',
    unauthorized: 'Du är inte inloggad',
    logout: 'Det uppstod ett problem vid utloggning',
    router: 'Det uppstod ett problem vid omdirigering',
    profile: {
      get: 'Det uppstod ett problem vid hämtning av användarinformation',
      save: 'Det uppstod ett problem vid uppdatering av användarinformation'
    },
    tenant: {
      set: 'Det uppstod ett problem vid byte av organisation'
    },
    avatar: {
      get: 'Profilbild saknas för kontot'
    },
    logo: {
      get: 'Logotyp saknas för kontot'
    }
  },

  crm: {
    default: {
      button: {
        save: 'Spara',
        delete: 'Ta bort',
        remove: 'Ta bort',
        add: 'Lägg till',
        confirm: 'Bekräfta',
        close: 'Stäng',
        cancel: 'Avbryt',
        ok: 'Ok',
        search: 'Sök',
        print: 'Skriv ut'
      }
    },
    general: {
      add: {
        label: 'Lägg till ny {0}'
      }
    },
    tags: {
      label: 'Etiketter',
      title: 'Etiketter'
    },
    contact: {
      name: 'Namn',
      companyName: 'Företagsnamn',
      firstName: 'Förnamn',
      middleName: 'Mellannamn',
      lastName: 'Efternamn',
      email: 'E-post',
      phone: 'Telefon',
      telephone: 'Telefon',
      title: 'Titel',
      sex: 'Kön',
      number: 'Kundnummer',
      description: 'Beskrivning',
      origin: 'Källa',
      address: {
        address: 'Adress',
        postalCode: 'Postnummer',
        city: 'Ort',
        region: 'Region/stat',
        country: 'Land'
      },
      postal: 'Postadress',
      visit: 'Besöksadress',
      delivery: 'Leveransadress',
      invoice: 'Fakturaadress',
      web: 'Hemsida',
      website: 'Hemsida',
      social: 'Social',
      internet: 'Internet',
      twitter: 'Twitter',
      facebook: 'Facebook',
      instagram: 'Instagram',
      linkedin: 'LinkedIn',
      facts: {
        edit: 'Redigera fakta'
      },
      tags: {
        label: 'Etiketter'
      },
      relations: {
        title: 'Relationer',
        primary: 'Primär relation',
        to: 'Relation till',
        notes: 'Notering',
        search: {
          employer: 'Arbetsgivare',
          hint: 'Börja skriva för att söka',
          type: 'Välp typ av relation'
        }
      },
      files: {
        label: 'Filer'
      },
      avatar: {
        upload: 'Välj bild...'
      },
      type: {
        company: 'Företag',
        person: 'Person'
      },
      button: {
        add: 'Ny kontakt'
      },
      show: {
        label: 'Visa kontakt',
        contactInfo: 'Kontaktuppgifter',
        facts: 'Fakta'
      },
      list:{
        label: 'Adressbok',
        title: 'Adressbok'
      },
      add:{
        title: 'Registrera ny kontakt'
      },
      create:{
        label: 'Registrera ny kontakt',
        title: 'Registrera ny kontakt',
        company: {
          title: 'Registrera nytt företag',
        },
        person: {
          title: 'Registrera ny person',
        }
      },
      edit: {
        title: 'Redigera kontaktuppgifter'
      },
      search: {
        title: 'Sök kontakt',
        addContact: 'Ny kontakt',
        searchHint: 'Sök på person- eller företags-namn',
        nameOrAddress: 'Sök namn eller adress'
      },
      company: {
        relations: {
          title: 'Relaterade kontakter'
        }
      },
      person: {
        relations: {
          title: 'Relaterade kontakter'
        }
      },
      summary: {
        title: 'Sammanfattning'
      }
    },
    content: {
      list: {
        filesTitle: 'Filer',
        action: ''
      },
      edit: {
        title: 'Redigera fil'
      },
      upload: {
        select: 'Välj fil(er)'
      },
      access: {
        label: 'Inställningar för åtkomst',
        anonymous: 'Delad',
        authenticated: 'Intern',
        private: 'Privat'
      },
      title: 'Titel',
      filename: 'Filnamn',
      length: 'Storlek',
      timeModified: 'Ändrad',
      password1: 'Lösenord',
      password2: 'Upprepa lösenord',
      author: 'Författare',
      tags: 'Etiketter'
    },
    notification: {
      clearAll: 'Rensa alla'
    },
    metadata: {
      contact: {
        relation: {
          type: 'Relationstyp'
        }
      },
      contract: {
        type: 'Avtalstyp',
        category: 'Avtalskategori',
        status: 'Avtalsstatus'
      },
      crm: {
        currency: 'Valuta'
      }
    },
    role: {
      title: 'Roll',
      name: 'Roll',
      label: 'Benämning',
      permissions: 'Behörigheter',
      list: {
        title: 'Användarroller'
      },
      owner: {
        label: 'Kontoadministratör'
      },
      admin: {
        label: 'Systemadministratör'
      },
      user: {
        label: 'Användare'
      },
      viewer: {
        label: 'Läsare'
      },
      guest: {
        label: 'Läsare'
      }
    },
    activity: {
      startTime: {
        label: 'Startar'
      },
      stopTime: {
        label: 'Slutar'
      },
      name: {
        label: 'Id'
      },
      title: {
        label: 'Benämning'
      },
      notes: {
        label: 'Beskrivning'
      },
      description: {
        label: 'Beskrivning'
      },
      progress: {
        label: 'Status',
        '-1': 'Avbokad',
        '0': 'Planerad',
        '50': 'Pågående',
        '100': 'Genomförd'
      },
      tags: {
        label: 'Etiketter'
      },
      location: {
        label: 'Plats'
      },
      uri: {
        label: 'Länk'
      },
      username: {
        label: 'Ansvarig'
      },
      reminder: {
        label: 'Påminnelse'
      },
      repeat: {
        label: 'Repetera',
        NONE: 'Aldrig'
      },
      show: {
        label: 'Aktivitet',
        title: 'Aktivitet'
      },
      create: {
        label: 'Ny aktivitet',
        title: 'Registrera ny aktivitet'
      },
      edit: {
        label: 'Ändra aktivitet',
        title: 'Ändra aktivitet'
      }
    }
  },

  inspectech: {
    documents: {
      templates: {
        name: 'Namn',
        template: 'Grundmall',
        types: 'Uppdragstyper'
      }
    },
    photos: {
      annotations: {
        rect: {
          label: 'Rektangel'
        },
        circle: {
          label: 'Cirkel'
        },
        arrow: {
          label: 'Pil'
        },
        text: {
          label: 'Text'
        }
      }
    },
    form: {
      scope: {
        ORDER: 'Beställning',
        PROJECT: 'Projekt',
        ESTATE: 'Fastighet',
        INSPECTION: 'Uppdrag',
        OBJECT: 'Objekt',
      },
      dataType: {
        TEXT: 'Text',
        INTEGER: 'Heltal',
        DECIMAL: 'Decimaltal',
        BOOLEAN: 'Ja/Nej',
        DATE: 'Datum',
        DATETIME: 'Klockslag',
        SINGLE: 'Enval',
        MULTIPLE: 'Flerval',
        TABLE: 'Tabell',
      },
    },
  },

  order: {
    search: {
      title: 'Sök beställningar'
    }
  },

  inspection: {
    number: {
      label: 'Uppdragsnummer'
    },
    littera: {
      label: 'Littera'
    },
    customer: {
      label: 'Uppdragsgivare'
    },
    address: {
      label: 'Adress'
    },
    tags: {
      label: 'Etiketter'
    },
    type: {
      label: 'Uppdragstyp'
    },
    branch: {
      label: 'Fackområde'
    },
    status: {
      label: 'Status'
    },
    orderNumber: {
      label: 'Beställningsnummer'
    },
    orderDate: {
      label: 'Beställningsdatum'
    },
    date: {
      label: 'Besiktningsdatum'
    },
    startTime: {
      label: 'Besiktningen startar'
    },
    stopTime: {
      label: 'Besiktningen slutar'
    },
    linked: {
      label: 'Länkat uppdrag'
    },
    inspector: {
      label: 'Besiktningsman'
    },
    orderSource: {
      label: 'Uppdragskälla'
    },
    search: {
      label: 'Sök uppdrag',
      title: 'Sök uppdrag'
    },
    create: {
      label: 'Nytt uppdrag',
      title: 'Registrera nytt uppdrag'
    }
  },

  estate: {
    location: {
      label: 'Kommun'
    },
    number: {
      label: 'Fastighetsbeteckning'
    },
    address: {
      label: 'Fastighetens adress'
    }
  },
  object: {
    name: {
      label: 'Objekt att besikta'
    },
  },

  logout: 'Logga ut',

  tenant: {
    name: 'Organisationsnamn',
    number: 'Org.nr',
    email: 'E-post',
    telephone: 'Telefon',
    description: 'Beskrivning',
    website: 'Hemsida',
    update: 'Uppdatera',
    address: {
      address: 'Adress',
      postalCode: 'Postnr',
      city: 'Ort',
      region: 'Kommun',
      country: 'Land'
    },
    edit: {
      title: 'Adressuppgifter',
      avatar: 'Centrerad logotyp (1:1)',
      logo1: 'Vänsterställd logotyp (3:1)',
      logo2: 'Högerställd logotyp (3:1)',
      update: 'Uppdatera',
    },
    invitation: {
      status: {
        created: 'Skickad',
        accepted: 'Accepterad',
        denied: 'Avböjt',
      }
    },
    settings: {
      order: {
        completed: {
          header: {
            label: 'Rubrik vid lagd beställlning',
            hint: 'När en kund registrerat en beställning visas denna rubrik i webbläsaren'
          },
          text: {
            label: 'Text/medddelande vid lagd beställlning',
            hint: 'När en kund registrerat en beställning visas denna text i webbläsaren'
          }
        },
        dispatcher: {
          email: {
            label: 'E-postadress dit beställningar skickas',
            hint: 'Om fältet lämnas blankt skickas beställningar till företagets e-postadress'
          }
        }
      }
    }
  },

  profile: {
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    title: 'Titel',
    company: 'Företag',
    number: 'Personnummer',
    email: 'E-post',
    telephone: 'Telefon',
    bio: 'Beskrivning',
    website: 'Hemsida',
    extra1: 'Certifieringsnummer ÖB',
    extra2: 'Certifieringsnummer EB',
    extra3: 'Övrigt',
    edit: {
      title: 'Kontaktuppgifter',
      picture: 'Klicka för att ändra profilbild',
      update: 'Uppdatera',
      certificates: 'Certifieringar',
      settings: 'Inställningar',
    }
  },

  user: {
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    title: 'Titel',
    email: 'E-post',
    role: {
      label: 'Roll',
      administrator: 'Administratör',
      user: 'Användare'
    },
    invite: {
      send: 'Skicka inbjudan'
    },
    settings: {
      geolocation: 'Lagra enhetens position',
      darkTheme: 'Mörkt utseende',
      startView: {
        label: 'Startvy vid inloggning',
        current: 'Aktiva uppdrag (lista)',
        calendar: 'Kalender',
        inbox: 'Nya beställningar'
      },
      homeAddress: 'Startadress för färdbeskrivningar',
      message: {
        updated: 'Inställningarna uppdaterade'
      }
    },
  },

  feature: {
    added: {
      message: 'Egenskapen tillagd i användaprofilen'
    },
    removed: {
      message: 'Egenskapen raderad från användaprofilen'
    },
    subscription: {
      label: 'Abonnemang',
    },
    storage: {
      label: 'Lagring',
    },
    integration: {
      label: 'Integration',
    },
    template: {
      label: 'Dokumentmallar',
    },
    membership: {
      label: 'Medlemskap',
      confirm: 'Bekräfta medlemskap'
    },
    certificate: {
      label: 'Certifiering',
      confirm: 'Bekräfta certifiering'
    }
  },

  inspect: {
    button: {
      accept: 'Acceptera'
    },
    inspection: {
      export: {
        title: 'Skriv ut / exportera',
        templates: 'Välj ett utskriftsformat',
        parameters: 'Ange parametrar för utskriften/exporten'
      }
    },
    cust: {
      hjerta: {
        error: 'Det gick inte att skapa försäkringsobjektet, kontrollera uppgifterna'
      }
    }
  },

  realestate: {
    index: {
      label: 'Fastigheter',
      title: 'Fastigheter'
    }
  },
  invoice: {
    settings: {
      bankgiro: 'Bankgiro',
      plusgiro: 'Plusgiro'
    }
  }
}
